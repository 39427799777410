export default class API {
  static URL = "https://www.semantics.gg/api";

  static async getDailyGame() {
    return fetch(`${API.URL}/daily`, {
      method: "GET",
    });
  }

  static async guess({ game, guess }) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        guess: guess.trim().toLowerCase(),
        game: game,
      }),
    };

    return fetch(`${API.URL}/game`, requestOptions).then((response) => response.json());
  }

  static async getHint({ game, previousHint }) {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        game,
        previousHint
      }),
    };

    return fetch(`${API.URL}/game/hint`, requestOptions).then((response) => response.json());
  }
}
