import { GlobalContextProvider } from "@/state/GlobalContext";
import "@/styles/globals.css";
import { ChakraProvider } from "@chakra-ui/react";
import { config, library } from "@fortawesome/fontawesome-svg-core";
import "@fortawesome/fontawesome-svg-core/styles.css";
import { faQuestionCircle, faLightbulb, faEllipsisVertical, faBackward } from "@fortawesome/free-solid-svg-icons";
import { Analytics } from '@vercel/analytics/react';

config.autoAddCss = false;

library.add(faQuestionCircle);
library.add(faLightbulb);
library.add(faEllipsisVertical);
library.add(faBackward);

export default function App({ Component, pageProps }) {
  return (
    <ChakraProvider>
      <GlobalContextProvider>
        <Component {...pageProps} />
        <Analytics />
      </GlobalContextProvider>
    </ChakraProvider>
  );
}
